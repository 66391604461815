import React from "react"
import Layout from "../../components/layout"
import styled from "styled-components"
import axios from "axios"
import AdminHeader from "../../components/admin/header";

const BuildLogContainer = styled.div`
  padding: 12px;
  .header {
    display: table;
    width: 100%;
    h2 {
      margin-bottom: 0;
      padding-bottom: 8px;
      display: table-cell;
    }
    .modified {
      font-size: 80%;
      text-align: right;
      display: table-cell;
      vertical-align: bottom;
    }
  }
  pre {
    border: 1px solid black;
    width: 100%;
    height: 600px;
    white-space: pre-wrap;
  }
`

class BuildLog extends React.Component {

  _isMounted = false

  constructor(props)
  {
    super(props)
    this.state = {
      buildLogTimer: null,
      log: '',
      modified: '000-00-00 00:00:00'
    }
    this.logRef = React.createRef()
  }

  componentDidMount()
  {
    this._isMounted = true
    this.refresh()
    const timer = setInterval(
      () => this.refresh()
      , 2000
    )
    this.setState({buildLogTimer: timer})
  }

  componentWillUnmount()
  {
    clearInterval(this.state.buildLogTimer)
    this._isMounted = false
  }

  shouldComponentUpdate(nextProps, nextState, nextContent) {
    return this.state.modified !== nextState.modified ||
      this.state.log !== nextState.log
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.logRef.current) {
      this.logRef.current.scrollTo(0, this.logRef.current.scrollHeight)
    }
  }

  refresh () {
    axios.get(process.env.GATSBY_API_URL, { params: {app: `build-log`}})
      .then(response => {
        if (this._isMounted) {
          this.setState({
            modified: response.data.modified,
            log: response.data.log
          })  
        }
      })
  }

  render () {
    return (
      <BuildLogContainer>
        <AdminHeader/>
        <div className="header">
          <h2>
            build.log
          </h2>
          <div className="modified">{ this.state.modified }</div>
        </div>
        <pre ref={this.logRef}>{ this.state.log }</pre>
      </BuildLogContainer>
    )
  }
}

export default ({ data }) => {
  return (
    <Layout>
      <BuildLog/>
    </Layout>
  )
}