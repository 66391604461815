import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  margin-bottom: 1rem;
`

const AdminHeader = () => (
  <Container>
    <Link to="/admin/">HOME</Link> |&nbsp;
    <Link to="/admin/support/">入力支援</Link>
  </Container>
)

export default AdminHeader